import React, { useState, useEffect } from "react";
import axios from "axios";

const VerifyComplete = (props) => {
  if (typeof window === "undefined") {
    var urlParams = new URLSearchParams({ f: "bar", l: "bar" });
  } else {
    var urlParams = new URLSearchParams(window.location.search);
  }
  //
  //
  //
  const [verifiedOrder, setverifiedOrder] = useState("");
  const [emailConfirmation, setemailConfirmation] = useState({
    response: "",
    time: "",
  });
  const [loadingDone, setloadingDone] = useState(false);
  //
  //
  //
  //Parameters needed to securely search order
  //
  const paramOrder = urlParams.get("o");
  const paramSupplier = urlParams.get("s");

  //
  //
  //
  //
  //
  //
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (paramSupplier) {
    case "Greenwave-Energy":
      var client = new faunadb.Client({
        secret: "fnAFYC5vuiAAQHKl0jbZM6ySKjTjhR80IwdpNLy0",
      });
      break;
    case "Callective-Energy":
      var client = new faunadb.Client({
        secret: "fnAFYC5pbEAAQoRZJRKoRzwaoIToIVr0UwhfwQrb",
      });
      break;
    case "Kratos-Ohio":
      var client = new faunadb.Client({
        secret: "fnAFYC6WwiAAQI93MiffluUh1iR_1I2AoxFZXj-6",
      });
      break;
    case "Kratos-Michigan":
      var client = new faunadb.Client({
        secret: "fnAFYC6M1yAAQJ8ocro91gzUxn4A6XkLOsgMbh01",
      });
      break;
    case "Kratos-Duke":
      var client = new faunadb.Client({
        secret: "fnAFYC5_wxAAQEBIW4p4gsDl6ScyWmpLdyj3PPd_",
      });
      break;

    default:
      var client = new faunadb.Client({
        secret: "",
      });
      break;
  }
  //
  //
  //
  //
  //
  //
  // Pull all Orders
  //
  //
  //
  //
  const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("order-details-by-ref"), Number(paramOrder))
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setverifiedOrder(allOrders.data);
    }
  };

  var matchedOrder = "default";

  switch (typeof verifiedOrder === "object") {
    case verifiedOrder === undefined:
      var matchedOrder = "existing orders is empty";
      break;
    case true:
      var matchedOrder = verifiedOrder[0].data;
      var order = matchedOrder;
      var question = matchedOrder.Product.script.tpvQuestions;
      var product = matchedOrder.Product;
      var productName = matchedOrder.Product.name;
      var productRate = matchedOrder.Product.rate;
      var productTerm = matchedOrder.Product.term;
      var productMonthlyFee = matchedOrder.Product.monthlyFee;
      var productTerminationFee = matchedOrder.Product.terminationFee;
      var productVendor = matchedOrder.Product.vendor.name;
      var dynamicQuestion = question
        .toString()
        .replace(
          /[\[\]]customer[\[\]]/g,
          order.firstName + " " + order.lastName
        )
        .replace(/[\[\]]date[\[\]]/g, new Date().toLocaleString())
        .replace(/[\[\]]rate[\[\]]/g, "$" + product.rate)
        .replace(/[\[\]]unit[\[\]]/g, product.unitMeasurement)
        .replace(/[\[\]]unit[\[\]]/g, product.unitMeasurement)
        .replace(/[\[\]]utility[\[\]]/g, product.utility.name)
        .replace(/[\[\]]utility_short[\[\]]/g, product.utility.nickname)
        .replace(/[\[\]]addr1[\[\]]/g, order.serviceAddress)
        .replace(/[\[\]]addr2[\[\]]/g, order.serviceSuite)
        .replace(/[\[\]]city[\[\]]/g, order.city)
        .replace(/[\[\]]state[\[\]]/g, order.serviceState)
        .replace(/[\[\]]zipcode[\[\]]/g, order.serviceZip)
        .replace(/[\[\]]bill_addr1[\[\]]/g, order.billingAddress)
        .replace(/[\[\]]bill_addr2[\[\]]/g, order.billingSuite)
        .replace(/[\[\]]bill_city[\[\]]/g, order.billingCity)
        .replace(/[\[\]]bill_state[\[\]]/g, order.billingState)
        .replace(/[\[\]]bill_zipcode[\[\]]/g, order.billingZip)
        .replace(/[\[\]]term[\[\]]/g, product.term)
        .replace(/[\[\]]monthlyFee[\[\]]/g, "$" + product.monthlyFee)
        .replace(/[\[\]]orderNumber[\[\]]/g, order.orderNumber);
      break;
  }
  //
  //

  //
  //
  const orderLoadingDone = () => {
    setTimeout(function () {
      setloadingDone(true);
    }, 2000);
  };
  //
  //
  //
  //

  useEffect(() => {
    if (typeof verifiedOrder === "string") {
      getAllOrders();
      orderLoadingDone();
    }

    setTimeout(function () {
      confirmationEmail();
    }, 2000);
  }, [verifiedOrder]);

  //
  //

  //
  //

  //

  //
  //

  //
  const confirmationEmail = async () => {
    const inputDate = new Date().toGMTString();
    let response = await axios.post(
      `/.netlify/functions/resi-form-${paramSupplier.toLowerCase()}`,
      {
        name: matchedOrder.firstName + " " + matchedOrder.lastName,
        accountName:
          matchedOrder.actHolderFirst + " " + matchedOrder.actHolderLast,
        accountRel: matchedOrder.actHolderRelation,
        accountNumber: matchedOrder.accountNumber_1,
        firstName: matchedOrder.firstName,
        lastName: matchedOrder.lastName,
        billing_address: matchedOrder.billingAddress,
        billing_suite: matchedOrder.billingSuite,
        billing_city: matchedOrder.billingCity,
        billing_state: matchedOrder.billingState,
        billing_zip: matchedOrder.billingZip,
        service_address: matchedOrder.serviceAddress,
        service_suite: matchedOrder.serviceSuite,
        service_city: matchedOrder.serviceCity,
        service_state: matchedOrder.serviceState,
        verification: matchedOrder.deliveryMethod,
        email: matchedOrder.email,
        verifyEmail: matchedOrder.verifyEmail,
        service_zip: matchedOrder.serviceZip,
        phone: matchedOrder.billingTel,
        phoneType: matchedOrder.telType,
        language: matchedOrder.language,
        timeEnrolled: inputDate,
        //////////// Product Stuff /////////////////
        product_name: productName,
        product_rate: productRate,
        product_term: productTerm,
        product_monthly_fee: productMonthlyFee,
        product_termination_fee: productTerminationFee,
        product_vendor: productVendor,
        tpv_questions: dynamicQuestion,
        order: matchedOrder,
        supplier: paramSupplier,
        signature: matchedOrder.signaturePng,
      }
    );

    const emailResponse = await response;

    setTimeout(function () {
      setemailConfirmation({
        ...emailConfirmation,
        response: emailResponse,
        time: inputDate,
      });
    }, 2000);
  };

  //
  //
  //
  //
  if (loadingDone === false && matchedOrder === "existing orders is empty") {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-gray-100 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div class="animate-fade p-4 rounded p-20 rounded-3xl  ">
              <div class="flex flex-col items-center space-y-2">
                <svg
                  aria-hidden="true"
                  class="mb-10 w-20 h-20 animate-spin text-gray-100 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <h1 class="text-6xl text-tpvBlue font-bold">Loading Order</h1>
                <p class="text-2xl text-gray-700">
                  Please wait while we load your order.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <>
        <div class="animate-fade bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-sky-300 to-cyan-200 flex items-center justify-center h-screen">
          <div class="animate-fade success bg-white p-20 shadow-xl rounded-3xl  ">
            <div class="text-emerald-400 flex flex-col items-center space-y-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="animate-bounce mb-3 w-28 h-28"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>
              <h1 class="text-center text-emerald-900 text-6xl font-bold">
                Order Complete & Verified{" "}
              </h1>{" "}
              <p class="pt-2 max-w-3xl text-center text-gray-700 text-2xl">
                Thank you for completing third party verification,
                <span class="pt-2 block font-bold">
                  your order number is:{" "}
                  <span class="text-orange-500">
                    {matchedOrder.orderNumber}.
                  </span>
                </span>{" "}
              </p>
              <p class="pb-6 pt-6 max-w-3xl text-center text-gray-700 text-2xl">
                An email has been sent to{" "}
                <span class="text-gray-400 font-medium underline">
                  {matchedOrder.email}
                </span>{" "}
                with your order details, please contact {props.supplier} with
                any questions.
              </p>
              {emailConfirmation.response.status === 200 ? (
                <p class="pt-3 max-w-3xl text-center text-green-700 text-xs border-t">
                  An email has been sent successfully on{" "}
                  {emailConfirmation.time}
                </p>
              ) : (
                <>
                  <button
                    type="button"
                    disabled
                    class="pt-3 max-w-3xl text-center text-gray-500 text-xs border-t "
                  >
                    Sending confirmation email...
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
};

export default VerifyComplete;
